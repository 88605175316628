import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'lui-input',
  templateUrl: './lui-input.component.html',
  styleUrls: ['./lui-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LuiInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LuiInputComponent),
      multi: true,
    },
  ],
})
export class LuiInputComponent
  implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('inputElement', { static: true, read: ElementRef }) inputElement;
  @ViewChild('inputElement', { static: true }) inputModel;
  // @ViewChild('textMeasure', {static: true}) textMeasure: ElementRef;

  @Input() suffix: string;
  @Input() prefix: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() icon: string;

  @Input() required: string;
  @Input() requiredError: string = 'Dieses Feld darf nicht leer sein!';

  @Input() email: string;
  @Input() emailError: string = 'Dies ist keine gültige E-Mail Adresse';

  @Input() minLength: number;
  @Input()
  minLengthError: string = `Mindestens ${this.minLength} Zeichen benötigt!`;

  @Input() maxLength: number;
  @Input()
  maxLengthError: string = `Maximal ${this.maxLength} Zeichen erlaubt!`;

  @Input() pattern: string | RegExp;
  @Input() patternError: string = 'Ungültige Eingabe!';

  @Input() hideValidation: string;
  @Input() showValidation: string;

  @Input() disabled: string;
  @Input() button: string;
  @Input() type:
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

  @Output() click = new EventEmitter<void>();

  width: number;
  private minWidth: number = 10;
  public inputContainerClasses: string[];

  private _value: string;

  public set value(value: string) {
    // console.log(value);
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
    // this.resizeInput(value);
    setTimeout(() => {
      this.inputContainerClasses = [
        this.inputModel.dirty ? 'ng-dirty' : 'ng-pristine',
        this.inputModel.valid ? 'ng-valid' : 'ng-invalid',
      ];
      return this.validate(this.inputModel);
    }, 0);
  }
  public get value() {
    return this._value;
  }

  private onChange: (_: any) => void = (_: any) => {};
  private onTouched: () => void = () => {};

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(c: FormControl) {
    // console.log(this.inputModel.errors);
    // console.log(c.errors);
    // console.log(c.hasError);
    // console.log(c.getError);
    return this.inputModel.errors;
  }

  public onClick() {
    this.click.emit();
  }
  // public getValidationClasses(){

  //   return [this.inputModel.dirty ? 'ng-dirty' : 'ng-pristine', this.inputModel.valid ? 'ng-valid' : 'ng-invalid']
  // }

  // private resizeInput(inputText) {
  //   setTimeout ( () =>{
  //     if (this.textMeasure.nativeElement.offsetWidth +2  > this.minWidth) {
  //       this.width = this.textMeasure.nativeElement.offsetWidth + 2;
  //     } else {
  //       this.width = this.minWidth;
  //     }

  //   }, 0);
  // }

  focusInput() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
      this.inputElement.nativeElement.select();
    }, 100);
  }

  constructor() {}

  ngOnInit(): void {}
}
