import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
 } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (!navigator.onLine) {
            errorMessage = 'Sie haben zurzeit keine Verbindung zum Internet';
          } else {
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
              console.log('Client Error!');

            } else {
              // server-side error
              errorMessage = `${error.error}`;

            }
          }
          // this.snackBar.open(errorMessage, '', {
          //   duration: 5000, horizontalPosition: 'right', panelClass: 'warn'
          // });
          console.log(error);
          return throwError(error);
        })
      );
  }
 }
