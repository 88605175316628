import { NgModule } from '@angular/core';
import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.directive';
import { AutoSizeInputDirective } from './auto-size-input/auto-size-input.directive';
import { HideProdDirective } from './hide-prod.directive';

@NgModule({
  declarations: [CopyToClipboardDirective],
  exports: [CopyToClipboardDirective],
})
export class CopyToClipboard {}

@NgModule({
  declarations: [AutoSizeInputDirective],
  exports: [AutoSizeInputDirective],
})
export class AutoSizeInput {}

@NgModule({
  declarations: [HideProdDirective],
  exports: [HideProdDirective],
})
export class HideProd {}
