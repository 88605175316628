import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findReplace'
})
export class FindReplacePipe implements PipeTransform {

  transform(value: string, findReplaceList:FindReplacePipeArg[]): string {
    let newValue = value;

    if(findReplaceList){
      findReplaceList.forEach(findReplace => {
        // console.log(value +" == "+ findReplace.find +" => "+(value == findReplace.find));
        if(value == findReplace.find){
          // console.log(findReplace.replace)
          newValue = findReplace.replace
          return;
        }
      })
    }

    return newValue;
  }

}

export interface FindReplacePipeArg {
  find: string;
  replace:string;
}
