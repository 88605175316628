import { NgModule } from '@angular/core';
import { DataTableComponent } from './data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import {
  AutoSizeInput,
  CopyToClipboard,
  HideProd,
} from '@app/directives/directives.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { InputNumberComponent } from './input-number/input-number.component';
import { LoadingIndicatorSmallComponent } from './loading-indicator-small/loading-indicator-small.component';
import { Pipes } from '@app/pipes/pipes.module';
import { LuiAlertComponent } from './lui-alert/lui-alert.component';
import { LuiAlertListComponent } from './lui-alert-list/lui-alert-list.component';
import { LuiSelectComponent } from './lui-select/lui-select.component';
import { LuiInputComponent } from './lui-input/lui-input.component';
import { DeepRouteHeaderComponent } from './deep-route-header/deep-route-header.component';
import { LuiButtonComponent } from './lui-button/lui-button.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { ViewComponent } from './view/view.component';
import { ViewActionBarComponent } from './view-action-bar/view-action-bar.component';
import { ViewTitleComponent } from './view-title/view-title.component';
import { CardTitleComponent } from './card-title/card-title.component';

@NgModule({
  declarations: [
    DataTableComponent,
    InputNumberComponent,
    LoadingIndicatorSmallComponent,
    LuiAlertComponent,
    LuiAlertListComponent,
    LuiSelectComponent,
    LuiInputComponent,
    DeepRouteHeaderComponent,
    LuiButtonComponent,
    DialogConfirmComponent,
    ViewComponent,
    ViewActionBarComponent,
    ViewTitleComponent,
    CardTitleComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    AutoSizeInput,
    MatProgressSpinnerModule,
    MatDialogModule,
    Pipes,
    CopyToClipboard,
    HideProd,
  ],
  exports: [
    DataTableComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    AutoSizeInput,
    MatProgressSpinnerModule,
    MatDialogModule,
    InputNumberComponent,
    LoadingIndicatorSmallComponent,
    LuiAlertComponent,
    LuiAlertListComponent,
    LuiSelectComponent,
    LuiInputComponent,
    DeepRouteHeaderComponent,
    LuiButtonComponent,
    DialogConfirmComponent,
    ViewComponent,
    ViewActionBarComponent,
    ViewTitleComponent,
    CardTitleComponent,
  ],
})
export class SharedComponents {}
