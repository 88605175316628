import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '@services/auth.service';

@Injectable(
{  providedIn: 'root'}
)
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectUrl = route['_routerState']['url']; // route._routerState.url
    const queryParams = route.queryParams;

    if (this.authService.isLoggedIn(queryParams.token)) {

      if (queryParams.token) {
        const urlTree = this.router.parseUrl(redirectUrl);
        delete urlTree.queryParams.token;
        return urlTree;
      } else {
        return true;
      }

    }
    return this.router.createUrlTree(['/login'], {
      queryParams: {redirectUrl, ...queryParams}
    });
  }


}
