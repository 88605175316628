<div class="border number-input-container">
  <button
    [disabled]="disabled"
    [ngClass]="disabled ? 'cursor-default' : 'cursor-pointer'"
    matRipple
    matRippleCentered="true"
    (mouseenter)="onMouseEnterRemove()"
    (mouseleave)="onMouseLeaveRemove()"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (touchstart)="onMouseDown()"
    (touchend)="onMouseUp()"
    class="noselect cursor-pointer bg-grey-300 color-text"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <div
    style="
      display: flex;
      flex-grow: 1;
      position: relative;
      justify-content: center;
      align-items: baseline;
    "
    class="bg-dialog"
    [ngClass]="disabled ? 'cursor-default' : 'cursor-text'"
    (mousedown)="focusInput()"
    (touchstart)="focusInput()"
  >
    <span
      #textMeasure
      style="white-space: pre; position: absolute; opacity: 0; z-index: -1;"
    >
      {{ value || '0' }}
    </span>
    <span *ngIf="prefix" class="suffix no-select color-gray-800">{{
      prefix
    }}</span>
    <input
      [disabled]="disabled"
      [ngClass]="disabled ? 'cursor-default' : 'cursor-text'"
      [ngStyle]="{ 'width.px': width }"
      #numberInput
      class="number-input bg-dialog color-gray-800"
      [ngModel]="value"
      (ngModelChange)="updateChanges()"
      type="number"
      [min]="min || 0"
      [max]="max"
      (keydown)="onKeypress($event)"
      (paste)="onPaste($event)"
    />
    <span
      *ngIf="suffix"
      class="suffix no-select color-gray-800"
      [class]="suffixClass"
      >{{ suffix }}</span
    >
  </div>
  <button
    [disabled]="disabled"
    [ngClass]="disabled ? 'cursor-default' : 'cursor-pointer'"
    matRipple
    matRippleCentered="true"
    (mouseenter)="onMouseEnterAdd()"
    (mouseleave)="onMouseLeaveAdd()"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (touchstart)="onMouseDown()"
    (touchend)="onMouseUp()"
    class="noselect cursor-pointer bg-grey-300 color-text"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
