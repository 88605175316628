import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestComponent } from './components/shared/test/test.component';
import { LoginComponent } from '@coreComponents/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ApplicationScaffoldComponent } from './components/core/application-scaffold/application-scaffold.component';

const routes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'app',
    },
    {
      path: 'index.html',
      redirectTo: 'app',
    },
    {
      path: 'login',
      component: LoginComponent,
    },
    {
      path: 'app',
      component: ApplicationScaffoldComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'emcc-app-config',
          outlet: 'emcc-app-config',
          loadChildren: () => import('./modules/configurators/app-config/app-config.module')
          .then(
            mod => mod.AppConfigModule
          ),
        },
        {
          path: 'taxide',
          outlet: 'taxide',
          loadChildren: () => import('./modules/taxide/taxide.module')
          .then(
            mod => mod.TaxideModule
          ),
        },
        {
          path: 'sql-tester',
          outlet: 'sql-tester',
          loadChildren: () => import('./modules/configurators/sql-tester/sql-tester.module')
          .then(
            mod => mod.SqlTesterModule
          ),
        },
        {
          path: 'sql-config',
          outlet: 'sql-config',
          loadChildren: () => import('./modules/configurators/sql-server/sql-server.module')
          .then(
            mod => mod.SqlServerModule
          ),
        },
        {
          path: 'data-component-showcase',
          outlet: 'DataComponentShowcase',
          loadChildren: () => import('./modules/data-components-showcase/data-components-showcase.module')
            .then(
              mod => mod.DataComponentsShowcaseModule
            ),
        },
        {
          path: 'fabix',
          outlet: 'Fabix',
          loadChildren: () => import('./modules/fabix/fabix.module')
            .then(
              mod => mod.FabixModule
            ),
        },
        {
          path: 'warehouse',
          outlet: 'Warehouse',
          loadChildren: () => import('./modules/warehouse/warehouse.module')
          .then(
            mod => mod.WarehouseModule
          ),
        },
        {
          path: 'test1',
          component: TestComponent,
          outlet: 'Test1',
        },
        {
          path: 'test2',
          component: TestComponent,
          outlet: 'Test2',
        },
        {
          path: 'test3',
          component: TestComponent,
          outlet: 'Test3',
        },
        {
          path: 'test4',
          component: TestComponent,
          outlet: 'Test4',

        },
        {
          path: 'test5',
          component: TestComponent,
          outlet: 'Test5',
        },
        {
          path: 'test6',
          component: TestComponent,
          outlet: 'Test6',
        },
    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
