import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

/* Angular Material */
import { MaterialModule } from '@modules/shared/material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { GermanPaginatorIntl } from './intl/german-paginator-intl';

import { DragDropModule } from '@angular/cdk/drag-drop';

/* Custom Components */
import { ApplicationScaffoldComponent } from '@coreComponents/application-scaffold/application-scaffold.component';
import { TabRouterOutletComponent } from '@coreComponents/tab-router-outlet/tab-router-outlet.component';
import { ModuleRouterOutletComponent } from '@coreComponents/module-router-outlet/module-router-outlet.component';
import { TestComponent } from '@sharedComponents/test/test.component';
import { LoginComponent } from '@coreComponents/login/login.component';
import { CopyToClipboard, AutoSizeInput } from '@directives/directives.module';
import { HttpErrorInterceptorService } from '@interceptors/http-error-interceptor.service';
import { PathLocationStrategy } from '@angular/common';
import { SnackBarComponent } from './components/core/snack-bar/snack-bar.component';
import { Pipes } from './pipes/pipes.module';
import { SharedComponents } from './components/shared/shared-components.module';

@NgModule({
  declarations: [
    AppComponent,
    ApplicationScaffoldComponent,
    TabRouterOutletComponent,
    ModuleRouterOutletComponent,
    TestComponent,
    LoginComponent,
    SnackBarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ScrollingModule,
    CopyToClipboard,
    AutoSizeInput,
    DragDropModule,
    Pipes,
    MaterialModule,
    SharedComponents,
    // MatToolbarModule,
    // MatSidenavModule,
    // MatListModule,
    // MatIconModule,
    // MatTabsModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCheckboxModule,
    // MatExpansionModule,
    // MatDialogModule,
    // MatInputModule
  ],
  providers: [
    PathLocationStrategy, //Wird benötigt um SSO auf BaseUrl ohne '#' zu ermöglichen, siehe auch AppComponent
    { provide: MatPaginatorIntl, useValue: GermanPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [SnackBarComponent],
})
export class AppModule {}
