<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content class="mb-3">
  {{ data.message }}
</div>
<mat-dialog-actions align="end">
  <lui-button
    class="m-1"
    mat-dialog-close
    label="Abrechen"
    color="danger"
  ></lui-button>
  <lui-button
    class="m-1"
    [mat-dialog-close]="true"
    cdkFocusInitial
    label="OK"
    color="success"
  ></lui-button>
</mat-dialog-actions>
