import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'lui-button',
  templateUrl: './lui-button.component.html',
  styleUrls: ['./lui-button.component.scss'],
})
export class LuiButtonComponent implements OnInit {
  public buttonClassList: string[] = [];
  public loaderClassList: string[] = [];

  @ViewChild('button') button: ElementRef;

  @Input() label: string;
  @Input() color:
    | 'primary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'grey' = 'primary';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() appearance: 'stroked' | 'flat' = 'flat';

  _disabled;
  @Input() disabled;
  @Input() block;
  @Input() noRadiusLeft;
  @Input() noRadiusRight;

  @Output() asyncAction = new EventEmitter<any>();
  width = 'auto';
  loading = false;

  @HostListener('click')
  handleClick() {
    if (this.disabled || this.disabled === '') return;
    if (this.asyncAction.observers.length === 0) return;

    this.loading = true;
    this.width = this.button?.nativeElement.clientWidth + 2 + 'px';
    this.asyncAction.emit(this.asyncActionDone);
  }
  asyncActionDone = () => {
    this.loading = false;
  };

  constructor() {}

  ngOnInit(): void {
    this.buttonClassList.push(
      'lui-button',
      this.color,
      this.size,
      this.appearance
    );
    if (this.block || this.block === '') {
      this.buttonClassList.push('block');
      this.width = '100%';
    }
    if (this.noRadiusLeft || this.noRadiusLeft === '') {
      this.buttonClassList.push('no-radius-left');
    }
    if (this.noRadiusRight || this.noRadiusRight === '') {
      this.buttonClassList.push('no-radius-right');
    }

    this.loaderClassList.push('loader', this.color, this.size, this.appearance);
  }
}
