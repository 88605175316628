import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';

export interface HttpOptions {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  observe?: any;
  params?: HttpParams | {
      [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}


@Injectable({
  providedIn: 'root'
})

export class EMCSService implements OnDestroy {
  private token: string = this.auth.token.value;
  private subscriptionToken: Subscription;

  // private url = 'https://online.emc-vision.de/emcs/entwicklung/emcserver.nsf/rest.xsp/services?';
  private url = 'https://online.emc-vision.de/emcs/test/emcserver.nsf/rest.xsp/services?';

  emcsGet(app, service, body?: string, options?: HttpOptions) {
    // let headers = new HttpHeaders()
    // .set('Authorization',  `Bearer ${ this.token }`);

    const params = new HttpParams()
    .set('app', app)
    .set('service', service)
    .set('token', this.token);


    let emcsOptions: HttpOptions = {
      params,
      observe: 'body',
      responseType: 'json'
    };

    emcsOptions = {...emcsOptions, ...options};

    // if(urlParams){
    //   for(let key of Object.keys(urlParams)){
    //     params.set(key,urlParams[key])
    //   }
    // }
    if (body) {
      return this.http.post(this.url, body, emcsOptions);
    } else {
      return this.http.get(this.url, emcsOptions);
    }

  }
  constructor(private http: HttpClient, private auth: AuthService) {
    this.subscriptionToken = this.auth.token.subscribe(token => {
      this.token = token;
    });
  }


  ngOnDestroy(): void {
    this.subscriptionToken.unsubscribe();
  }
}
