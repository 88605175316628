import { Component, OnInit } from '@angular/core';
import { ModuleTabService } from '@app/services/module-tab.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'emcc-edit-article-detail',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  providers: [ModuleTabService],
})
export class TestComponent implements OnInit {
  nichts(event) {
    return;
  }

  test(event: Subject<void>) {
    console.log(event);
    event.subscribe();
    setTimeout(() => {
      console.log('times up!');
      event.complete();
    }, 5000);
  }

  ngOnInit() {}
}
