import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  ElementRef,
  forwardRef,
  ChangeDetectorRef,
  OnChanges,
  AfterContentChecked,
  AfterViewChecked,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'emcc-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
})
export class InputNumberComponent
  implements OnInit, ControlValueAccessor, Validator {
  _value: number;
  mousepressed: boolean;
  overAdd: boolean;
  overRemove: boolean;
  interval = 200;
  width: number;
  valChanged: boolean;

  @ViewChild('numberInput', { static: true }) numberInput;
  @ViewChild('textMeasure', { static: true }) textMeasure: ElementRef;

  // @Output() valueChange = new EventEmitter<number>();

  @Input() step: number;
  @Input() focus: boolean;
  // @Input('value')
  @Input() minWidth = 20;
  @Input() suffix: string;
  @Input() prefix: string;
  @Input() min: number = 0;
  @Input() max: number;
  @Input() disabled = false;

  @Input() suffixClass: string[];
  valid: any;
  timer: NodeJS.Timeout;

  set value(value: number) {
    this._value = value;
    // this.valueChange.emit(this._value);
    this.valid = null;
    if (value < this.min) {
      this.valid = { valid: false };
    }
    if (value > this.max) {
      this.valid = { valid: false };
    }

    this.onChange(this._value);
    this.onTouched();

    this.resizeInput(value);
  }
  get value() {
    return this._value;
  }

  focusInput() {
    setTimeout(() => {
      this.numberInput.nativeElement.focus();
      this.numberInput.nativeElement.select();
    }, 100);
  }

  resizeInput(inputText) {
    if (this.textMeasure.nativeElement.offsetWidth + 2 > this.minWidth) {
      this.width = this.textMeasure.nativeElement.offsetWidth + 2;
    } else {
      this.width = this.minWidth;
    }
  }

  constructor() {}

  add() {
    console.log(this.disabled);
    const step = this.step || 1;
    if (this.value) {
      if (this.max) {
        if (this.value + step < this.max) {
          this.value = this.value + step;
        } else {
          this.value = +this.max;
        }
      } else {
        this.value = this.value + step;
      }
    } else {
      this.value = step;
    }
  }

  remove() {
    const step = this.step || 1;

    if (this.value && this.value - step > this.min) {
      this.value = this.value - step;
    } else {
      this.value = +this.min;
    }
  }

  onKeypress(event) {
    console.log(event.key);
    if (
      event.key.match(new RegExp('[0-9]')) ||
      event.key == 'Delete' ||
      event.key == 'Backspace'
    ) {
      setTimeout(() => {
        this.value = parseInt(event.target.value);
      });
    } else if (
      event.key == 'ArrowLeft' ||
      event.key == 'ArrowRight' ||
      event.key == 'ArrowUp' ||
      event.key == 'ArrowDown' ||
      event.key == 'Home' ||
      event.key == 'End'
    ) {
      return true;
    } else {
      return false;
    }
  }

  onPaste(event) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (!pastedText.match(new RegExp('[0-9]'))) {
      return false;
    } else {
      setTimeout(() => {
        this.value = parseInt(event.target.value);
      });
    }
  }

  onMouseEnterAdd() {
    this.overAdd = true;
  }
  onMouseLeaveAdd() {
    this.overAdd = false;
  }

  onMouseEnterRemove() {
    this.overRemove = true;
  }
  onMouseLeaveRemove() {
    this.overRemove = false;
  }

  onMouseDown() {
    this.mousepressed = true;
    clearTimeout(this.timer);
    this.count();
  }

  onMouseUp() {
    this.mousepressed = false;
    this.interval = 200;
  }

  count() {
    if (this.mousepressed) {
      if (this.overRemove) {
        this.remove();
        this.timer = setTimeout((x) => {
          this.count();
        }, this.interval);
      } else if (this.overAdd) {
        this.add();
        this.timer = setTimeout((x) => {
          this.count();
        }, this.interval);
      } else {
        this.mousepressed = false;
        this.interval = 200;
      }

      if (this.interval >= 10) {
        this.interval -= 10;
      } else {
        this.interval = 10;
      }
    }
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  writeValue(value: number): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(c: FormControl) {
    return this.valid;
  }

  ngOnInit() {
    this.resizeInput(this.value || '0');
  }

  ngAfterContentInit() {
    if (focus) {
      setTimeout(() => {
        this.numberInput.nativeElement.focus();
        this.numberInput.nativeElement.select();
      }, 500);
    }
  }

  // ngAfterContentChecked(){
  //   this.resizeInput(this.value);
  // }
}
