<div class="view-container">
  <div class="animate" [ngClass]="isVisible ? 'show' : 'hide'">
    <emcc-deep-route-header class="header" [displayName]="viewTitle">
      <button
        nav
        mat-icon-button
        (click)="onNavigate(['warehouse', clientUnid])"
        matTooltip="Zurück zur Artikelübersicht"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button
        nav
        mat-icon-button
        (click)="onNavigate(['warehouse', clientUnid])"
        matTooltip="Zurück zum Dashboard"
      >
        <mat-icon>dashboard</mat-icon>
      </button>
    </emcc-deep-route-header>
  </div>
  <div class="content-wrapper" #contentWrapper>
    <div *ngIf="!loading; else viewLoading" [class]="contentLayout">
      <lui-alert-list *ngIf="!loading" [alerts]="errors"></lui-alert-list>
      <ng-content *ngIf="canReadContent"></ng-content>
      <div
        class="animate"
        [ngClass]="isVisible ? 'bottom-hide' : 'bottom-show'"
      ></div>
    </div>
    <ng-template #viewLoading>
      <mat-spinner class="spinner"></mat-spinner>
    </ng-template>
  </div>
</div>
