import { NgModule } from '@angular/core';
import { FilterObjectValuesByStringPipe } from './filter-object-values-by-string/filter-object-values-by-string.pipe';
import { SafePipe } from './safe.pipe';
import { FindReplacePipe } from './find-replace.pipe';
import { SortByPipe } from './sort-by.pipe';


@NgModule({
  declarations: [
    FilterObjectValuesByStringPipe,
    FindReplacePipe,
    SafePipe,
    SortByPipe
  ],
  exports: [
    FilterObjectValuesByStringPipe,
    FindReplacePipe,
    SafePipe,
    SortByPipe
  ]
})
export class Pipes {}
