import {
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  NgZone,
  OnInit,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  moveItemInArray,
  CdkDropList,
  CdkDragStart,
} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { stringify } from 'querystring';
import { FindReplacePipeArg } from '@app/pipes/find-replace.pipe';
import { Pipes } from '@app/pipes/pipes.module';

export enum InputType {
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimelocal = 'datetime-local',
  email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
  select = 'select',
}

export interface DataTableConfig {
  data: any;
  title?: string;
  paginator?: {
    active?: boolean;
    itemsPerPageOptions?: number[];
    itemsPerPageDefault?: number;
  };
  filter?: {
    active?: boolean;
  };
  sorting?: {
    active?: boolean;
    sortableComlumns?: string[];
    initiallySortedColumn?: string;
    initialSortingDirection?: 'asc' | 'desc';
    disableClear?: boolean;
  };
  findReplace?: FindReplacePipeArg[];
  selectable?: {
    active?: boolean;
  };
  editable?: {
    active: boolean;
    cells?: boolean;
    delete?: boolean;
    add?: boolean;
  };

  displayedColumns?: string[];
  columns?: {
    index?: number;
    key: string;
    displayName: string;
    inputType?: InputType;
    dateFormat?: string;
    findReplace?: FindReplacePipeArg[];
    selectValueForColumn?: string;
    selectOptions?: { displayName: string; value: any }[];
    validators?: {
      required?: boolean;
      email?: boolean;
      minLength?: number | boolean;
      maxLength?: number | boolean;
      pattern?: string | boolean;
    };
  }[];
}

@Component({
  selector: 'emcc-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit {
  itemClickedHasObervers: boolean;
  @Input('config')
  set config(config: DataTableConfig) {
    if (config) {
      this.status = 'Lade Konfiguration...';

      this._config = this.setConfig(config);
      this.dataSource = new MatTableDataSource(config.data);

      setTimeout(() => {
        if (this.config.sorting.active) {
          this.dataSource.sort = this.sort;
        }
        if (this.config.paginator.active) {
          this.dataSource.paginator = this.paginator;
        }
      });
      this.loading = false;
    }
  }

  get config(): DataTableConfig {
    return this._config;
  }

  constructor(private dialog: MatDialog, private zone: NgZone) {
    this.loading = true;
    this.selection = new SelectionModel<any>(true, []);
    this.selection.changed.subscribe(() => {
      this.itemsSelected.emit(this.selection.selected);
    });
  }

  ngOnInit() {
    this.itemClickedHasObervers = this.itemClicked.observers.length > 0;
    console.log(this.itemClicked.observers);
  }

  dataSource: MatTableDataSource<any>;
  // dataPrev: any;
  selectMode: boolean; // true = auswählen / false = abwählen
  selecting: boolean;
  selection: SelectionModel<any>;
  previousIndex: number;
  editMode: boolean;
  editCellMode: boolean;
  loading: boolean;
  // changedRows:any[]=[];
  @Input() status: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  _config: DataTableConfig;

  @Output() itemClicked: any = new EventEmitter();
  @Output() itemsSelected: any = new EventEmitter();
  @Output() rowChanged: any = new EventEmitter();
  @Output() actionDelete: any = new EventEmitter();
  @Output() actionAdd: any = new EventEmitter();

  @HostListener('window:mouseup', ['$event'])
  mouseUp(event) {
    this.selecting = false;
  }

  setConfig(config) {
    let newConfig: DataTableConfig;

    if (config) {
      newConfig = { data: config.data };
      newConfig.title = config.title || 'Title';

      if (config.columns) {
        newConfig.displayedColumns =
          config.displayedColumns || config.columns.map((column) => column.key);
        newConfig.columns = [];
        config.columns.forEach((column, index) => {
          if (column.validators) {
            column.validators = {
              required: column.validators.required || false,
              email: column.validators.email || false,
              minLength: column.validators.minLength || false,
              maxLength: column.validators.maxLength || false,
              pattern: column.validators.pattern || false,
            };
          } else {
            column.validators = {
              required: true,
              email: false,
              minLength: null,
              maxLength: false,
              pattern: false,
            };
          }
          newConfig.columns[index] = column;
        });
      } else {
        const datakeys = (() => {
          let tmpKeys = [];
          config.data.forEach((obj) => {
            tmpKeys = [...tmpKeys, ...Object.keys(obj)]; // die keys jeden objekts im data-array werden tmpKeys hinzugefügt
          });
          return tmpKeys.filter(
            (item, index) => tmpKeys.indexOf(item) === index
          ); // gibt deduplizierte keys von tmpKeys zurück
        })();

        newConfig.displayedColumns = config.displayedColumns || [...datakeys];
        newConfig.columns = datakeys.map((x) => ({
          displayName: x,
          key: x,
          validators: {
            required: false,
            email: false,
            minLength: null,
            maxLength: false,
            pattern: false,
          },
        }));
      }

      if (config.paginator) {
        newConfig.paginator = {
          active:
            config.paginator.active != null ? config.paginator.active : true,
          itemsPerPageDefault: config.paginator.itemsPerPageDefault || 25,
          itemsPerPageOptions: config.paginator.itemsPerPageOptions || [
            25,
            50,
            150,
            600,
          ],
        };
      } else {
        newConfig.paginator = {
          active: true,
          itemsPerPageDefault: 25,
          itemsPerPageOptions: [25, 50, 150, 600],
        };
      }

      if (config.filter) {
        newConfig.filter = {
          active: config.filter.active || false,
        };
      } else {
        newConfig.filter = {
          active: false,
        };
      }

      if (config.sorting) {
        newConfig.sorting = {
          active: config.sorting.active || false,
          // sortableComlumns: config.sorting.sortableComlumns || [...datakeys],
          initiallySortedColumn: config.sorting.initiallySortedColumn,
          initialSortingDirection: config.sorting.initialSortingDirection,
          disableClear: config.sorting.disableClear || false,
        };
      } else {
        newConfig.sorting = {
          active: false,
          // sortableComlumns: [...datakeys],
          disableClear: false,
        };
      }

      if (config.selectable) {
        newConfig.selectable = { active: config.selectable.active || false };
      } else {
        newConfig.selectable = { active: false };
      }

      if (config.editable) {
        newConfig.editable = {
          active: config.editable.active || false,
          cells: config.editable.cells || false,
          delete: config.editable.delete || false,
          add: config.editable.add || false,
        };
      } else {
        newConfig.editable = {
          active: false,
          cells: false,
          delete: false,
          add: false,
        };
      }

      newConfig.findReplace = config.findReplace;

      if (newConfig.selectable.active) {
        newConfig.displayedColumns.unshift('select');
      }
    }
    return newConfig;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  startSelect(row) {
    this.selectMode = !this.selection.isSelected(row);
    this.selecting = true;
  }
  mouseoverSelect(row) {
    if (this.selecting) {
      if (this.selectMode !== this.selection.isSelected(row)) {
        this.selection.toggle(row);
      }
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  handleClick(row) {
    if (!this.editMode && !this.editCellMode) {
      this.itemClicked.emit(row);
    }
  }

  setDisplayedColumns() {
    const columns = this.config.columns.filter((x) =>
      this.config.displayedColumns.includes(x.key)
    );
    if (this._config.selectable.active) {
      columns.forEach((colunm, index) => {
        colunm.index = index;
        this.config.displayedColumns[index + 1] = colunm.key;
      });
    } else {
      columns.forEach((colunm, index) => {
        colunm.index = index;
        this.config.displayedColumns[index] = colunm.key;
      });
    }
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.config.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  removeColumn(column) {
    this.config.displayedColumns.splice(
      this.config.displayedColumns.indexOf(column.key),
      1
    );
  }

  setPaginator(active) {
    if (active) {
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      });
    } else {
      this.dataSource.paginator = null;
    }
  }

  setSelectable(active) {
    if (active) {
      this.config.displayedColumns.unshift('select');
    } else {
      this.config.displayedColumns.splice(0, 1);
    }
  }

  getUnusedColumns() {
    const unusedKeys = this.config.columns.filter(
      (x) => !this.config.displayedColumns.includes(x.key)
    );
    return unusedKeys;
  }

  addColumn(key) {
    this.config.displayedColumns.push(key);
  }

  logConfig() {
    const { data, ...config } = this.config;
    console.log(JSON.stringify(config));
  }

  onRowChanged(row) {
    this.rowChanged.emit(row);
  }

  onSelectInput(row, column, value) {
    const selectOption = column.selectOptions.find(
      (x) => x.displayName === value
    );
    row[column.key] = value;
    row[column.selectValueForColumn] = selectOption.value;
    this.onRowChanged(row);
  }

  onDelete() {
    this.actionDelete.emit();
  }

  onAdd(data) {
    this.actionAdd.emit(data);
  }

  log(msg) {
    console.log(msg);
  }

  openDialog(templateRef: TemplateRef<any>) {
    const dialogRef = this.dialog.open(templateRef);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onAdd(result.value);
      }
    });
  }
}
