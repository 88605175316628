import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  StatusResponseType,
  StatusResponse,
} from '@app/interfaces/status-response';

@Component({
  selector: 'lui-alert',
  templateUrl: './lui-alert.component.html',
  styleUrls: ['./lui-alert.component.scss'],
})
export class LuiAlertComponent implements OnInit, StatusResponse {
  @Input('type') type: StatusResponseType = 'info';
  @Input('dismissible') dismissible: boolean;
  @Input('message') message: string = 'Keine weiteren Informationen verfügbar.';
  @Input('typeMessage') typeMessage: string;

  @Output() close = new EventEmitter<void>();
  backgroundClass: string;

  onClose() {
    this.close.emit();
  }

  constructor() {}

  ngOnInit(): void {
    this.backgroundClass = BackgroundClass[this.type];

    if (!this.typeMessage) {
      this.typeMessage = AlertMessageStrong[this.type];
    }
  }
}

enum BackgroundClass {
  warning = 'bg-warning-lighter',
  success = 'bg-success-lighter',
  danger = 'bg-danger-lighter',
  info = 'bg-info-lighter',
}

enum AlertMessageStrong {
  warning = 'Warnung!',
  success = 'Erfolgreich!',
  danger = 'Fehler!',
  info = 'Information:',
}
