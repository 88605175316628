import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'emcc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'EMCC';

  constructor(router: Router, pathLocationStrategy: PathLocationStrategy) {
    //falls die die Url zu beginn URL-Encoded ist, wird diese zunächst decoded
    const checkEncodeUri = new RegExp(/\%/i);
    if(checkEncodeUri.test(location.href)){
      router.navigateByUrl(decodeURIComponent(location.hash.replace('#','')))
    }else{
      //,Wird benötigt um SSO auf BaseUrl ohne '#' zu ermöglichen, siehe auch 'PathLocationStrategy' in AppModule ProviderArray
      const basePath = pathLocationStrategy.getBaseHref();
      const absolutePathWithParams = pathLocationStrategy.path();
      if (basePath !== absolutePathWithParams) {
        router.navigateByUrl(absolutePathWithParams);
      }
    }
  }
}
