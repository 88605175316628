import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'emcc-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent implements OnInit {
  @Input() headline: string;
  @Input() subtitle: string;

  constructor() {}

  ngOnInit(): void {}
}
