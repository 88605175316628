import { Component, OnInit, Input } from '@angular/core';
import { BookmarkService } from '@app/services/bookmark.service';
import { ModuleTabService } from '@app/services/module-tab.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tab } from '@app/interfaces/tab';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TabService } from '@app/services/tab-service.service';

@Component({
  selector: 'emcc-deep-route-header',
  templateUrl: './deep-route-header.component.html',
  styleUrls: ['./deep-route-header.component.scss']
})
export class DeepRouteHeaderComponent implements OnInit {
  _displayName: string;

  @Input()
  set displayName(displayName: string) {
    if (displayName) {
      const url = [];
      this.route.snapshot.url.forEach(x => { url.push(x.path); });
      let parent: ActivatedRouteSnapshot = this.route.snapshot.parent;
      while (parent) {
        url.unshift(parent.url.join('/'));
        parent = parent.parent;
      }


      const [base, app, main, ...deep] = url;

      this.tab = {
        displayName,
        moduleName: this.tabService.appList.value.find(x => x.url.main === main).moduleName,
        url: {
          main,
          deep
        },
      };
      this.url = this.moduleTabService.getTabUrl(this.tab);
    }
    this._displayName = displayName;
  }
  get displayName() {
    return this._displayName;
  }

  url: string;
  tab: Tab;

  isBookmark(tab) {
    return this.bookmarkService.isBookmark(tab);
  }

  addBookmark(tab: Tab) {
    return this.bookmarkService.addBookmark(tab);
  }

  removeBookmark(tab: Tab) {
    return this.bookmarkService.removeBookmark(tab);
  }


  openSnackBar(msg) {
    this.snackBar.open(msg, '', {
      duration: 2000, horizontalPosition: 'right'
    });
  }
  constructor(
    private bookmarkService: BookmarkService,
    private moduleTabService: ModuleTabService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private tabService: TabService
  ) { }

  ngOnInit() {

  }

}
