


<div class="lui-select__container">
  <label *ngIf="label" class="lui-select__label color-grey-700" for="inputElement">{{label}}<span *ngIf="!!required || required == ''"> *</span></label>
  <div *ngIf="!label && (placeholder || placeholder =='')" style="overflow: hidden;">
    <label  for="inputElement" class="lui-select__placeholder-label color-grey-600" [ngClass]="value=='' || !value ? '':'lui-select__placeholder-label--show'">{{placeholder}}<span *ngIf="!!required || required == ''"> *</span></label>
  </div>
  <div class="border color-grey-700 align-center-container" [ngClass]=" !!disabled || disabled===''? ['lui-select--disabled','cursor-default'] : ['bg-dialog', 'cursor-text']"  style="max-height: 36px; box-sizing: border-box;">
    <div *ngIf="icon" style="padding: 0 .7rem; height: 36px" class="align-center-container cursor-default" >
      <mat-icon [ngClass]=" !!disabled || disabled===''? 'color-grey-600' : 'color-grey-700'">{{icon}}</mat-icon>
    </div>
    <select
      style="flex-grow: 1"
      id="inputElement"
      #inputElement="ngModel"
      [compareWith]="compareFn"
      [required]="!!required || required === ''"
      [disabled]="!!disabled || disabled===''"
      class="input-font lui-select__input-container border"
      [ngClass]="[
        !!disabled || disabled===''
        ? 'lui-select--disabled color-grey-600'
        : (!hideValidation && !(hideValidation =='')) && (showValidation || showValidation =='' || !!required || required === '')
          ? 'show-validation bg-dialog color-grey-700'
          : 'bg-dialog color-grey-700',icon ? 'no-radius-left' :'',button ? 'no-radius-right':'']"
      [ngModel]="value"
      (ngModelChange)="writeValue($event);"
    >
      <option class="bg-dialog"  *ngIf="placeholder && (((!!required || required === '') && !value) || (!required && required != ''))" [disabled]="!!required || required === ''" [value]="undefined" selected>{{placeholder ? !!required || required == '' ? placeholder+' *' : placeholder : ''}}</option>
      <option class="bg-dialog" *ngFor="let option of options" [ngValue]="option">{{getName(option)}}</option>
    </select>
    <!-- <mat-icon *ngIf="inputElement.invalid && !hideValidation && hideValidation!='' && inputElement.dirty" style="margin-left: auto" class="color-danger-075">error</mat-icon> -->
    <!-- <mat-icon *ngIf="inputElement.valid && !hideValidation && hideValidation!='' && inputElement.dirty" style="margin-left: auto" class="color-success-075">done</mat-icon> -->
    <lui-button noRadiusLeft color="grey" [label]="button" [disabled]="inputElement.invalid || !!disabled || disabled===''" *ngIf="button"  (click)="onClick()"></lui-button>

  </div>
  <label *ngIf="!inputElement.errors" for="inputElement" class="lui-select__hint color-grey-600">{{hint}}</label>
  <label *ngIf="inputElement.errors?.required && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-select__hint color-danger">{{requiredError}}</label>
</div>

