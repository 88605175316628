import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { of, Subject, BehaviorSubject } from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn = new BehaviorSubject<boolean>(false);

  private _token: string;
  public username: string;

  public token  = new BehaviorSubject<string>(this._token);
  private redirectUrl: string;

  isLoggedIn(queryParamToken?) {
    const token =  this._token || queryParamToken || sessionStorage.getItem('token');
    if (token) {
      const setToken = this.setToken(token);
      if (setToken === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  authError(error: HttpErrorResponse) {
    if (error.status !== 0) {
      return of ({success: false, message: 'Anmeldung fehlgeschlagen!'});
    } else {
      return of ({success: false, message: 'Es ist ein unbekannter Fehler aufgetreten, Bitte prüfen Sie Ihre Internetverbindung'});
    }
  }

  login(username: string, password: string) {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Basic ${btoa(username + ':' + password)}`)
    };
    return this.http.get('https://online.emc-vision.de/authserver.nsf/rest.xsp/getToken', header)
    .pipe(
      retry(2),
      map((res: {token: string}) => {
        if (!res.hasOwnProperty('token')) {
          this.loggedIn.next(false);
        }
        const setToken = this.setToken(res.token);
        if (setToken === true) {
          this.username = username;
          this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.redirectUrl || '/app');
          return {success: true, message: 'Anmeldung erfolgreich, Sie werden weitergeleitet'};
        } else {
          return setToken;
        }
      }),
      catchError(this.authError)
      );

  }

  logout() {
    sessionStorage.removeItem('token');
    this._token = undefined;
    this.loggedIn.next(false);
    this.router.navigateByUrl('/login');
  }

  validateToken(token) {

    token = JSON.parse(atob(token.split('.')[1]));
    if (new Date() > new Date(token.exp * 1000)) {
      return {
        success: false,
        message: 'Der übergebene Token ist abgelaufen, Bitte melden Sie sich erneut mit Ihrem Benutzernamen und Kennwort an.'
      };
    }
    if (token.aud !== 'https://openid.emc-kassel.de') {
      return {
        success: false,
        message: 'Der übergebene Token ist nicht für diese Zielgruppe vorgesehen, Bitte melden Sie sich erneut mit Ihrem Benutzernamen und Kennwort an'
      };
    }
    if (!token.user) {
      return {
        success: false,
        message: 'Der übergebene Token enthält keinen Benutzer, Bitte melden Sie sich erneut mit Ihrem Benutzernamen und Kennwort an'
      };
    }
    if (token.iss !== 'https://emc-kassel.de') {
      return {
        success: false,
        message: 'Der übergebene Token wurde nicht von "https://emc-kassel.de" ausgestellt, Bitte melden Sie sich erneut mit Ihrem Benutzernamen und Kennwort an'
      };
    }
    return true;
  }

  setToken(token) {
    const validateToken = this.validateToken(token);
    if (validateToken === true) {
      this._token = token;
      this.token.next(this._token);

      sessionStorage.setItem('token', token);
      this.loggedIn.next(true);
      return true;
    } else {
      this.logout();
      return validateToken;
    }
  }
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }
}
