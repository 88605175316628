import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from '@app/interfaces/snack-bar-data';
import { SnackBarComponent } from '@coreComponents/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  public openSnackBar(data:SnackBarData){

    console.log(data.icon)

    if(!data.icon){
      data.icon = IconForType[data.type]
      console.log(data.icon)
    }



    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: data.duration || 2000,
      data: data,
      horizontalPosition: 'right',
      panelClass: data.type
    });
  }

  constructor(private snackBar: MatSnackBar) { }
}

enum IconForType{
  warning= 'warning',
  success='done',
  danger='error',
  info='info',
  none=''
}
