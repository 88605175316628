import { Component, OnInit, Input } from '@angular/core';
import { StatusResponse } from '@app/interfaces/status-response';

@Component({
  selector: 'lui-alert-list',
  templateUrl: './lui-alert-list.component.html',
  styleUrls: ['./lui-alert-list.component.scss']
})
export class LuiAlertListComponent implements OnInit {
  @Input('alerts') luiAlertList:StatusResponse[];

  onCloseItem(index){
    this.luiAlertList.splice(index,1);
  }
  constructor() { }

  ngOnInit(): void {
  }

}
