<div>
  <div style="display: flex;flex-direction: column;">
    <div class="edit-box" *ngIf="!editMode && config && config.editable.active">
      <div class="edit-box-row">
        <ng-content select="[actions]"></ng-content>
        <button
          *ngIf="config.editable.cells"
          [disabled]="config.data.length == 0"
          mat-flat-button
          [ngClass]="editCellMode ? 'bg-success' : 'bg-grey-300'"
          class="lui-button"
          (click)="editCellMode = !editCellMode"
        >
          <mat-icon>edit</mat-icon>
          Zellen bearbeiten
        </button>
        <button
          *ngIf="config.editable.add"
          mat-flat-button
          class="lui-button bg-success"
          (click)="openDialog(addRow)"
        >
          <mat-icon>add</mat-icon>
          Neuer Eintrag
        </button>
        <button
          *ngIf="config.editable.delete"
          mat-flat-button
          [disabled]="selection.selected.length == 0"
          class="lui-button bg-danger"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon>
          Löschen
        </button>
      </div>
    </div>
    <div *ngIf="editMode" class="edit-box">
      <div class="edit-box-row">
        <mat-slide-toggle [(ngModel)]="config.filter.active"
          >Suchfeld anzeigen?</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="config.paginator.active"
          (change)="setPaginator(config.paginator.active)"
          >Paginator verwenden?</mat-slide-toggle
        >
        <mat-slide-toggle [(ngModel)]="config.sorting.active"
          >Spalten sortierbar?</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="config.selectable.active"
          (change)="setSelectable(config.selectable.active)"
          >Spalten markierbar?</mat-slide-toggle
        >
        <mat-slide-toggle
          [(ngModel)]="config.editable.active"
          (change)="setSelectable(config.selectable.active)"
          >Zeilen bearbeitbar?</mat-slide-toggle
        >
      </div>

      <div class="edit-box-row" style="margin-top: 1rem;">
        <button
          mat-flat-button
          class="lui-button bg-grey-300"
          [disabled]="getUnusedColumns().length == 0"
          [matMenuTriggerFor]="addColumnMenu"
        >
          <mat-icon>add</mat-icon>
          Spalte hinzufügen
        </button>
        <mat-menu #addColumnMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let column of getUnusedColumns()"
            (click)="addColumn(column.key)"
          >
            <span>{{ column.key }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="fill-spacer"></div>

    <!-- <div>
      <ng-content select="[tools]"></ng-content>

      <button mat-icon-button [matMenuTriggerFor]="tableMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #tableMenu="matMenu">
        <button mat-menu-item (click)="editMode = !editMode">
          <mat-icon>edit</mat-icon>
          <span>Bearbeiten</span>
        </button>
        <button mat-menu-item (click)="logConfig()">
          <span>LogConfig</span>
        </button>
      </mat-menu>
    </div> -->

    <ng-content
      *ngIf="loading"
      style="padding-bottom: 1rem;"
      select="[status]"
    ></ng-content>
    <div class="table-container" *ngIf="config">
      <div
        *ngIf="config.filter.active"
        style="
          padding: 0 1rem;
          margin-bottom: 2rem;
          display: flex;
          margin-top: 1rem;
        "
      >
        <div class="lui-input-icon-container-before">
          <mat-icon>search</mat-icon>
        </div>
        <input
          (keyup)="applyFilter($event.target.value)"
          class="lui-input"
          placeholder="Suchbegriff eingeben.."
        />
      </div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortActive]="config.sorting.initiallySortedColumn"
        [matSortDirection]="config.sorting.initialSortingDirection"
        [matSortDisabled]="!config.sorting.active || editMode"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0);"
        cdkDropListGroup
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="select-column">
            <mat-checkbox
              [disabled]="config.data.length == 0"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="select-column">
            <mat-checkbox
              (mousedown)="startSelect(row)"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let column of config.columns; let i = index"
          [matColumnDef]="column.key"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="column.key"
            cdkDropList
            cdkDropListLockAxis="x"
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dropListDropped($event, i)"
            cdkDrag
            [cdkDragDisabled]="!editMode"
            (cdkDragStarted)="dragStarted($event, i)"
            [cdkDragData]="{ name: column.key, columIndex: i }"
          >
            <input
              [emccAutoSizeInput]="column.displayName.length"
              class="table-header-input color-text"
              [disabled]="!editMode"
              [(ngModel)]="column.displayName"
            />
            <mat-icon
              matRipple
              [matRippleUnbounded]="true"
              [matRippleCentered]="true"
              *ngIf="editMode"
              (click)="removeColumn(column)"
              >close</mat-icon
            >
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <input
              *ngIf="editCellMode && column.inputType != 'select'"
              [type]="[column.inputType] || 'text'"
              [emccAutoSizeInput]="row[column.key].length"
              [disabled]="!editCellMode"
              [(ngModel)]="row[column.key]"
              (change)="onRowChanged(row)"
              [name]="column.key + '' + i"
              class="table-cell-input color-text"
              [required]="column.validators.required"
              [email]="column.validators.email"
              [attr.minLength]="column.validators.minLength"
              [attr.maxLength]="column.validators.maxLength"
              [attr.pattern]="column.validators.pattern"
            />
            <select
              [ngModel]="row[column.key]"
              *ngIf="editCellMode && column.inputType == 'select'"
              class="table-cell-input color-text"
              (change)="
                onSelectInput(row, column, $event.target.value);
                onRowChanged(row);
                log($event)
              "
              ><!-- (change)="onSelectInput(row, column, $event)"[(ngModel)]="getColumnFromKey(column).key" -->
              <option
                *ngFor="let selectOption of column.selectOptions"
                [selected]="selectOption.displayName == row[column.key]"
                [value]="selectOption.displayName"
                >{{ selectOption.displayName }}</option
              >
            </select>
            <span *ngIf="!editCellMode && column.inputType != 'date'">{{
              row[column.key]
                | findReplace: column.findReplace
                | findReplace: config.findReplace
            }}</span>
            <span *ngIf="!editCellMode && column.inputType == 'date'">{{
              row[column.key] | date: column.dateFormat
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="no-entries">
          <th
            mat-header-cell
            *matHeaderCellDef
            [attr.colspan]="config.displayedColumns.length"
          >
            <!-- <h5 class="heading-5" style="text-align: center; margin: 1rem 0;">Derzeit sind keine Einträge vorhanden.</h5> -->
            <div style="margin-top: 1rem;">
              <lui-alert
                typeMessage="Derzeit sind keine Einträge vorhanden."
                message=""
              ></lui-alert>
            </div>
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="config.displayedColumns"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="['no-entries']"
          [ngStyle]="{
            display: config.data.length != 0 ? 'none' : 'table-row'
          }"
        ></tr>
        <tr
          mat-row
          class="hover-grey"
          (mouseover)="mouseoverSelect(row)"
          (click)="handleClick(row)"
          [ngClass]="[
            last ? 'no-border-bottom-row' : '',
            itemClickedHasObervers ? 'cursor-pointer' : ''
          ]"
          *matRowDef="
            let row;
            columns: config.displayedColumns;
            let last = last
          "
        ></tr>
      </table>
      <mat-paginator
        *ngIf="
          config.paginator.active &&
          config.data.length > config.paginator.itemsPerPageOptions[0]
        "
        [disabled]="!config.paginator.active"
        [pageSize]="config.paginator.itemsPerPageDefault"
        [pageSizeOptions]="config.paginator.itemsPerPageOptions"
      ></mat-paginator>
      <!-- <div class="fill-spacer" style="height:1rem"></div>         -->
    </div>
    <!-- [ngStyle]="{'display': config.paginator.active == true ? 'none' : 'flex'}" -->
    <ng-template #addRow>
      <form #form="ngForm">
        <h1 mat-dialog-title>
          Neuer Eintrag
        </h1>
        <mat-dialog-content>
          <div *ngFor="let field of config.columns; let i = index">
            <ng-container
              *ngIf="config.displayedColumns.indexOf(field.key) > -1"
            >
              <label [for]="field" class="small muted">{{
                field.displayName
              }}</label>
              <input
                *ngIf="field.inputType != 'select'"
                [type]="field.inputType"
                class="lui-input color-text"
                ngModel
                [name]="field.key"
                [id]="field.key"
              />
              <select
                *ngIf="field.inputType == 'select'"
                class="lui-input"
                ngModel
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="
                  form.value[field.key] = $event.displayName;
                  form.value[field.selectValueForColumn] = $event.value
                "
              >
                <option
                  *ngFor="let selectOption of field.selectOptions"
                  [ngValue]="selectOption"
                  >{{ selectOption.displayName }}</option
                >
              </select>
              <input
                *ngIf="field.inputType == 'select'"
                type="hidden"
                [ngModel]="form.value[field.key]"
                [name]="field.key"
              />
              <input
                *ngIf="field.selectValueForColumn"
                type="hidden"
                [ngModel]="form.value[field.selectValueForColumn]"
                [name]="field.selectValueForColumn"
              />
            </ng-container>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-flat-button matDialogClose color="grey-300">
            Abbrechen
          </button>
          <button mat-flat-button [mat-dialog-close]="form" color="success">
            Erstellen
          </button>
        </mat-dialog-actions>
      </form>
    </ng-template>
  </div>
</div>
