<div class="grid-container" [ngClass]="alternativeTheme == true ? 'alternative-theme' : ''">
    <mat-toolbar color="primary" class="noselect">
        <button mat-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
        <span>E/M/C Client</span>
        <span class="fill-spacer"></span>
        <button  matTooltip="Verwende die alternative Farb-Palette" mat-icon-button (click)="toggleAlternativeTheme()">
          <mat-icon>palette</mat-icon>
      </button>
        <button  matTooltip="Abmelden" mat-icon-button (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
        </button>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav #sidenav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"  class="noselect">
            <mat-nav-list>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header
                    [expandedHeight]="'60px'"
                    [collapsedHeight]="'60px'"
                    class="border-bottom color-grey-800"
                    >
                      <mat-panel-title>
                        Apps
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                        <a
                          mat-list-item
                          *ngFor="let module of moduleList"
                          (click)="navigateTab(module,true)"
                          class="color-grey-600"
                        >
                          {{module.displayName}}
                        </a>

                </mat-expansion-panel>
                <!-- <a mat-list-item routerLink="." >Sidebar</a> -->
                <mat-expansion-panel
                    [ngClass]="{'bg-primary-50': globalBookmarkDragging}"
                    [expanded]="bookmarksOpenState"
                    (opened)="bookmarksOpenState = true"
                    (closed)="bookmarksOpenState = false">

                    <mat-expansion-panel-header
                        [expandedHeight]="'60px'"
                        [collapsedHeight]="'60px'"
                        class="border-bottom color-grey-800"
                      >
                        <mat-panel-title>
                            Lesezeichen
                        </mat-panel-title>
                    </mat-expansion-panel-header
                    >
                    <div
                        cdkDropList
                        id="linkList"
                        cdkDropList
                        [cdkDropListData]="bookmarkList"
                        [cdkDropListConnectedTo]="['linkListDelete']"
                        (cdkDropListDropped)="dropLink($event)"
                        (cdkDropListEntered)="bookmarksOpenState=true"
                    >
                    <a
                        mat-list-item
                        *ngFor="let bookmark of bookmarkList"
                        (click)="navigateTab(bookmark)"
                        class="color-grey-600"
                        cdkDragLockAxis="y"
                        cdkDragBoundary=".mat-expansion-panel-body"
                        cdkDrag
                        [cdkDragStartDelay]="300"
                        (cdkDragReleased)="bookmarkDragging=false"
                        (cdkDragStarted)="bookmarkDragging=true"
                        [cdkDragData]="bookmark">
                        {{bookmark.displayName}}
                    </a>
                    </div>
                    <div [ngStyle]="{'opacity': bookmarkDragging ? '1' : '0'}"
                        [ngClass]="linkListDeleteHover ? 'bg-danger' : 'color-grey-600'"
                        style="display: flex; justify-content: center; padding: .5rem; align-items: center; transition: background-color 0.25s ease-in-out 0s;"
                        id="linkListDelete"
                        cdkDropList
                        [cdkDropListData]="bookmarkList"
                        (cdkDropListDropped)="dropLinkDelete($event)"
                        (cdkDropListEntered)="dropLinkDeleteEnterered($event)"
                        (cdkDropListExited)="dropLinkDeleteExited($event)"
                        cdkDropListOrientation="horizontal"
                        >

                        <mat-icon>delete</mat-icon>
                        <!-- <span style="line-height: 24px;">LÖSCHEN</span> -->
                    </div>
                    <!-- <a mat-list-item *ngFor="let link of tabService.linkList" (click)="navigateTab(link)">{{link.displayName}}</a> -->
                </mat-expansion-panel>
            </mat-nav-list>
        </mat-sidenav>
<!-- [ngStyle]="{'height' : multipleTabsOpened ? 'auto' : '0'}" -->
        <mat-sidenav-content class="grid-container">
            <div class="animate" [ngClass]="multipleTabsOpened ? 'show' : 'hide'">
                <nav  class="noselect bg-dialog" style="width: 100%" role="tablist" mat-tab-nav-bar cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                    <a *ngFor="let tab of openTabs"
                        mat-tab-link
                        [active]="activeTab===tab"
                        (click)="activateTab(tab)"
                        cdkDragLockAxis="x"
                        cdkDragBoundary="[mat-tab-nav-bar]"
                        cdkDrag
                        [cdkDragStartDelay]="300"
                        class="color-grey-600"
                        >

                        <span *ngIf="tab">{{tab.displayName}}</span>
                        <mat-icon (click)="closeTab(tab)">close</mat-icon>
                    </a>
                </nav>
            </div>
            <div *ngFor="let module of moduleList" class="tabcontent" [ngClass]="{ 'active':activeTab===module}" >
                <emcc-tab-router-outlet [module]="module"></emcc-tab-router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
