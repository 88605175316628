import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emcc-loading-indicator-small',
  templateUrl: './loading-indicator-small.component.html',
  styleUrls: ['./loading-indicator-small.component.scss']
})
export class LoadingIndicatorSmallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
