import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModuleNavService {
  moduleName: string;
  navHistory: string[][] = [];

  navigateModule(url) {
    this.navHistory.push(url);
    console.log(this.moduleName);
    this.router.navigate(['app/', { outlets: { [this.moduleName]: url } }]);
    console.log(this.navHistory);
  }

  navigateBack() {
    const url = this.navHistory[this.navHistory.length - 2];
    // this.navigateModule(url);
    this.router.navigate(['app/', { outlets: { [this.moduleName]: url } }]);
    this.navHistory.pop();
    console.log(this.navHistory);
  }

  constructor(private router: Router) {}
}
