import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TabService } from '@services/tab-service.service';
import { BookmarkService } from '@services/bookmark.service';
import { AuthService } from '@services/auth.service';
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { Tab } from '@app/interfaces/tab';
import { TestService } from './test.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'emcc-application-scaffold',
  templateUrl: './application-scaffold.component.html',
  styleUrls: ['./application-scaffold.component.scss'],
  providers: [TestService],
})
export class ApplicationScaffoldComponent implements OnDestroy {
  public mobileQuery: MediaQueryList;
  bookmarksOpenState = true;
  bookmarkDragging = false;
  linkListDeleteHover = false;

  globalBookmarkDragging = this.bookmarkService.bookmarkDragging.value;
  subscriptionGlobalBookmarkDragging: Subscription;

  bookmarkList = this.bookmarkService.bookmarkList.value;
  // set bookmarkList(bookmarkList){
  //   this._bookmarkList == bookmarkList;
  //   this.bookmarkService.updateUserSetting()
  // }
  // get bookmarkList(){
  //   return this._bookmarkList;
  // }
  alternativeTheme = false;

  subscriptionBookmarkList: Subscription;

  multipleTabsOpened = false;

  openTabs: Tab[] = [];
  subscriptionOpenTabs: Subscription;

  activeTab: Tab;
  subscriptionActiveTab: Subscription;

  moduleList = this.tabService.appList.value;
  subscriptionModuleList: Subscription;

  mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private tabService: TabService,
    private bookmarkService: BookmarkService,
    private authService: AuthService,
    private overlayContainer: OverlayContainer
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.subscriptionGlobalBookmarkDragging = this.bookmarkService.bookmarkDragging.subscribe(
      (dragging) => {
        this.globalBookmarkDragging = dragging;
      }
    );

    this.subscriptionBookmarkList = this.bookmarkService.bookmarkList.subscribe(
      (bookmarkList) => {
        this.bookmarkList = bookmarkList;
      }
    );

    this.subscriptionModuleList = this.tabService.appList.subscribe(
      (moduleList) => {
        this.moduleList = moduleList;
      }
    );

    this.subscriptionOpenTabs = this.tabService.tabs.subscribe((tabs) => {
      setTimeout(() => {
        this.openTabs = tabs;
        this.multipleTabsOpened = tabs.length > 1;
      });
    });

    this.subscriptionActiveTab = this.tabService.activeTab.subscribe(
      (activeTab) => {
        setTimeout(() => {
          this.activeTab = activeTab;
        });
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.openTabs, event.previousIndex, event.currentIndex);
    this.tabService.tabs.next(this.openTabs);
  }
  dropLink(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      console.log(this.bookmarkList);
      this.bookmarkService.updateBookmarkList(this.bookmarkList);
    } else {
      if (!this.bookmarkService.isBookmark(event.item.data.displayName)) {
        this.bookmarkService.addBookmark(event.item.data);
        moveItemInArray(this.openTabs, event.previousIndex, event.currentIndex);
        this.tabService.tabs.next(this.openTabs);
      }
    }
  }

  dropLinkDelete(item) {
    this.bookmarkService.removeBookmark(item.item.data);
  }
  dropLinkDeleteEnterered(item) {
    this.linkListDeleteHover = true;
  }
  dropLinkDeleteExited(item) {
    this.linkListDeleteHover = false;
  }

  navigateTab(tab: Tab, noOverwrite?: boolean) {
    this.tabService.navigateTab(tab, noOverwrite);
  }
  closeTab(tab: Tab) {
    this.tabService.closeTab(tab);
  }

  activateTab(tab: Tab) {
    this.tabService.activateTab(tab);
  }

  logout() {
    this.authService.logout();
  }

  toggleAlternativeTheme() {
    this.alternativeTheme = !this.alternativeTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    if (this.alternativeTheme) {
      classList.add('alternative-theme');
    } else {
      classList.remove('alternative-theme');
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.subscriptionGlobalBookmarkDragging.unsubscribe();
    this.subscriptionBookmarkList.unsubscribe();
    this.subscriptionOpenTabs.unsubscribe();
    this.subscriptionActiveTab.unsubscribe();
    this.subscriptionModuleList.unsubscribe();

    this.tabService.destroy();
  }
}
