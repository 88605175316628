import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'emcc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}
  loginMsg: { success: boolean; message: string };
  username: string;
  password: string;

  login(event) {
    this.authService.login(this.username, this.password).subscribe((result) => {
      event.call();
      this.loginMsg = result;
      if (!result.success) {
        this.password = '';
      }
    });
  }

  ngOnInit() {
    // this.route.queryParams.subscribe(params => {
    //   if(params.token){
    //     this.loginMsg = this.authService.loginByToken(params.token);
    //   }
    // })
  }
}
