import { Component, OnInit, forwardRef, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { get } from 'lodash';

@Component({
  selector: 'lui-select',
  templateUrl: './lui-select.component.html',
  styleUrls: ['./lui-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LuiSelectComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LuiSelectComponent),
    multi: true,
  }]
})
export class LuiSelectComponent implements OnInit {
  @ViewChild('inputElement', { static: true, read: ElementRef }) inputElement;
  @ViewChild('inputElement', { static: true }) inputModel;
  @ViewChild('textMeasure', { static: true }) textMeasure: ElementRef;

  @Input() hint: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() icon: string;

  @Input() required: string;
  @Input() requiredError: string = 'Dieses Feld darf nicht leer sein!';

  @Input() hideValidation: string;
  @Input() showValidation: string;
  @Input() disabled: string | boolean;
  @Input() button: string;

  @Input() options: any[] = [];
  @Input() optionNameIdentifier: string[];

  private _compareValue;
  public compareFn = (optionOne, optionTwo): boolean => {
    if (!optionOne || !optionTwo) {
      return false;
    }
    return optionOne === optionTwo;
  };
  @Input() set compareValue(compareValue) {
    this._compareValue = compareValue;

    if (!compareValue) {
      // console.log('!compareValue')
      this.compareFn = (optionOne, optionTwo): boolean => {
        if (!optionOne || !optionTwo) {
          return false;
        }
        return optionOne === optionTwo;
      }
    } else if (compareValue.length == 1) {
      // console.log('compareValue.length == 1');
      this.compareFn = (optionOne, optionTwo): boolean => {
        // console.log(get(optionOne,compareValue[0])+' === '+get(optionTwo,compareValue[0])+'=>'+(get(optionOne,compareValue[0]) === get(optionTwo,compareValue[0])));
        if (!optionOne || !optionTwo) {
          return false;
        }
        if (optionOne === optionTwo) {
          return true
        }
        return get(optionOne, compareValue[0]) === get(optionTwo, compareValue[0])
      }
    } else if (compareValue.length == 2) {
      if (compareValue[1].length == 0) {
        // console.log('compareValue.length == 2 && compareValue[1].length == 0')

        this.compareFn = (optionOne, optionTwo): boolean => {
          if (!optionOne || !optionTwo) {
            return false;
          }
          if (optionOne === optionTwo) {
            return true
          }
          // console.log(`${get(optionOne, compareValue[0])} === ${optionTwo}=>${get(optionOne, compareValue[0]) === optionTwo}`)

          return get(optionOne, compareValue[0]) === optionTwo;
        }
      } else {
        // console.log('compareValue[1].length != 0')
        this.compareFn = (optionOne, optionTwo): boolean => {
          // console.log(get(optionOne,compareValue[0])+' === '+get(optionTwo,compareValue[1])+'=>'+(get(optionOne,compareValue[0]) === get(optionTwo,compareValue[1])));
          if (!optionOne || !optionTwo) {
            return false;
          }
          if (optionOne === optionTwo) {
            return true
          }
          return get(optionOne, compareValue[0]) === get(optionTwo, compareValue[1])
        }
      }
    }
  }

  get compareValue() {
    return this._compareValue;
  }

  @Output() click = new EventEmitter<void>();

  public inputContainerClasses: string[];

  private _value: string;

  public set value(value: string) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
    this.inputContainerClasses = [this.inputModel.dirty ? 'ng-dirty' : 'ng-pristine', this.inputModel.valid ? 'ng-valid' : 'ng-invalid'];
  }
  public get value() {
    return this._value;
  }



  private onChange: (_: any) => void = (_: any) => { };
  private onTouched: () => void = () => { };


  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  public validate(c: FormControl) {
    return {
      ...this.inputModel.errors
    }
  }

  public onClick() {
    this.click.emit();
  }

  public getName(option) {
    if (!this.optionNameIdentifier) {
      return option;
    } else {
      return get(option, this.optionNameIdentifier);
    }
  }



  constructor() { }

  ngOnInit(): void {
  }

}
