import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Tab } from '@app/interfaces/tab';
import { Router } from '@angular/router';

@Injectable()
export class ModuleTabService {
  tabRoutes: Tab[] = [];
  tabs: Tab[] = [];
  moduleName: string;
  moduleBaseUrl: string;
  activeTab: string;

  addTab(params) {
    // Params['component'] wird in die Variable component überführt, der Rest der Parameter in componentParams
    let { componentUrl, displayName, ...componentParams } = params;
    let tab: Tab;

    if (!displayName) {
      this.tabRoutes.find((tabRoute) => {
        // console.log(componentUrl +"=="+tab.url.sub+"?");
        if (tabRoute.url.sub === componentUrl) {
          displayName = tabRoute.displayName;
          return true;
        }
      }); // wenn zu der Route ohne displayName navigiert wurde, wird dieser aus den TabRoutes ermittelt
    }
    // es wird eine eindeutige TabId anhand der Daten erzeugt
    const tabId = this.generateTabId(
      JSON.stringify([componentUrl, displayName])
    );

    if (!this.tabs.find((tab) => tab.id === tabId)) {
      const tabRoute = this.tabRoutes.find(
        (tabRoute) => tabRoute.url.sub === componentUrl
      );

      tab = Object.assign({}, tabRoute); // die gefundene tabRoute wird in ein neuen Object kopiert

      if (displayName) {
        tab.displayName = displayName;
      }

      tab.url.data = componentParams; // diesem neuen Object werden Parameter zugewiesen
      tab.id = tabId;

      this.tabs.push(tab);
    }
    this.activateTab(tabId);
  }

  getTabUrl(tab: Tab) {
    // url wird zum substring der aktuellen URL von Beginn bis zu dem ersten "/" nach einem "//" (also index plus 2 für die beiden /)
    // die // sollten immer noch dem http: oder https: auftauchen, der nächste / ist also das Ende der aktuellen base URL
    let url = window.location.href.substr(
      0,
      window.location.href.indexOf('/', window.location.href.indexOf('//') + 2)
    );

    // die url wird mit den Parametern für:
    // 1. das Router-Outlet (=moduleName), gefolgt von einem ":"
    // 2. die BaseUrl, subUrl und displayName jeweils getrennt durch ein "/"
    url +=
      '/#/app/(' +
      encodeURIComponent(tab.moduleName) +
      ':' +
      encodeURIComponent(this.moduleBaseUrl);

    if (tab.url.sub) {
      url += '/' + encodeURIComponent(tab.url.sub);
    }
    if (tab.displayName && !tab.url.deep) {
      url += '/' + encodeURIComponent(tab.displayName);
    }
    if (tab.url.deep) {
      tab.url.deep.forEach((x) => {
        url += '/' + encodeURIComponent(x);
      });
    }
    // 3. die data für das objekt getrennt durch Semikolons als "key=value"
    if (tab.url.data) {
      for (const key of Object.keys(tab.url.data)) {
        url += ';' + key + '=' + encodeURIComponent(tab.url.data[key]);
      }
    }
    // 4. eine schließende Klammer
    url += ')';
    return url;
  }

  activateTab(tabId) {
    this.activeTab = tabId;
    const tab = this.tabs.find((tab) => tab.id === tabId);
    this.navigateModule([
      this.moduleBaseUrl,
      tab.url.sub,
      tab.displayName,
      tab.url.data,
    ]);
  }

  closeTab(tabId: string) {
    this.tabs.splice(this.tabs.map((x) => x.id).indexOf(tabId), 1);
    if (this.activeTab === tabId) {
      if (this.tabs.length) {
        this.activateTab(this.tabs[0].id);
      } else {
        this.navigateModule(null);
      }
    }
  }

  getTabById(tabId) {
    return this.tabs.find((tab) => tab.id === tabId);
  }

  generateTabId(s) {
    let h: number;
    for (let i = 0; i < s.length; i++) {
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    }
    return h.toString();
  }

  navigateModule(url) {
    this.router.navigate(['app/', { outlets: { [this.moduleName]: url } }]);
  }

  constructor(private router: Router, private location: Location) {}
}
