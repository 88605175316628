import {
  Component,
  OnInit,
  ViewChild,
  Injectable,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { StatusResponse } from '@app/interfaces/status-response';
import { ModuleNavService } from '@app/services/module-nav.service';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import {
  throttleTime,
  map,
  pairwise,
  distinctUntilChanged,
  share,
  filter,
} from 'rxjs/operators';
import { SnackBarService } from '@app/services/snack-bar.service';

export interface EMCCViewConfig {
  viewTitle: string;
  contentLayout: 'fluid' | 'fix';
  readView: boolean;
}
enum Direction {
  Up = 'Up',
  Down = 'Down',
}

@Component({
  selector: 'emcc-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, AfterViewInit {
  public isVisible = true;

  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  public clientUnid: string;
  public loading = true;
  public errors: StatusResponse[] = [];
  public get viewTitle() {
    if (!this.viewModel.config) return 'Fehler!'; // aktuell wird immer {} zurückgegeben, wenn keine config vorhanden ist, daher immer false
    if (!this.canReadContent) return 'Zugriff verweigert';
    return this.viewModel.config.viewTitle;
  }

  public get canReadContent() {
    return this.viewModel.config.readView;
  }

  public get contentLayout() {
    switch (this.viewModel.config.contentLayout) {
      case 'fluid':
        return 'view-fluid';
      case 'fix':
        return 'view-fix';
      default:
        return 'view-fluid';
    }
  }

  public onScroll() {
    console.log('Scrolling!');
  }

  public onNavigate(url) {
    this.viewModel.navigate(url);
  }

  constructor(private viewModel: ViewModel) {
    this.clientUnid = viewModel.clientUnid;
  }

  ngOnInit(): void {
    if (!this.viewModel.config?.readView) {
      this.errors.push({
        type: 'warning',
        message: 'Sie sind nicht zum Lesen dieser Ansicht berechtigt',
      });
      this.loading = false;
      return;
    }

    this.viewModel.initData.forEach((initData) => {
      initData.then(
        () => {},
        (err) => {
          this.errors.push(err);
        }
      );
    });
    Promise.allSettled(this.viewModel.initData).then(() => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    console.dir(this.contentWrapper.nativeElement);

    const scroll$ = fromEvent(this.contentWrapper.nativeElement, 'scroll').pipe(
      throttleTime(10),
      map(() => this.contentWrapper.nativeElement.scrollTop),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter((direction) => direction === Direction.Up)
    );

    const scrollDown$ = scroll$.pipe(
      filter((direction) => direction === Direction.Down)
    );

    scrollUp$.subscribe(() => {
      this.isVisible = true;
    });
    scrollDown$.subscribe(() => {
      this.isVisible = false;
    });
  }
}

@Injectable()
export class ViewModel {
  private pendingActions: Promise<any>[] = [];
  public get actionPending() {
    return this.pendingActions.length !== 0;
  }
  public config;
  public clientUnid;
  public initData: Promise<any>[] = [];

  registerPendingAction(action: Promise<any>) {
    this.pendingActions.push(
      action.then(
        () => {
          this.pendingActions.splice(this.pendingActions.indexOf(action), 1);
        },
        (err) => {
          this.pendingActions.splice(this.pendingActions.indexOf(action), 1);
        }
      )
    );
  }

  handleError(action: Promise<any>) {
    this.registerPendingAction(
      action.then(
        () => {},
        (err) => {
          this.snackBar.openSnackBar(err);
        }
      )
    );
  }

  public navigate(url: string[]) {
    this.moduleNavService.navigateModule(url);
  }

  public setInitData(initData: Promise<any>[]) {
    this.initData = initData;
  }

  constructor(
    public moduleNavService: ModuleNavService,
    public route: ActivatedRoute,
    public snackBar: SnackBarService
  ) {
    this.config = this.route.snapshot.data.config;
    this.route.parent.params.subscribe((parentParams) => {
      this.clientUnid = parentParams.client_unid;
    });
  }
}
