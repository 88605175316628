<div style="display: flex; padding: .5rem; flex-wrap: wrap; justify-content: center; align-items: center;" class="border-bottom bg-dialog cursor-default">
    <ng-content select="[nav]"></ng-content>

    <div style="flex-grow:1; text-align: center;">
        <div class="display-3" style="margin: 0">
            <span *ngIf="displayName">{{displayName}}</span>
            <emcc-loading-indicator-small *ngIf="!displayName"></emcc-loading-indicator-small>
            <!-- <ng-content select="[title]"></ng-content> -->
        </div>
    </div>
    <div>
        <ng-content select="[controls]"></ng-content>
        <button matTooltip="Lesezeichen entfernen" mat-icon-button (click)="removeBookmark(tab);" *ngIf="isBookmark(tab); else notBookmarked">
            <mat-icon >bookmark</mat-icon>

        </button>

        <ng-template #notBookmarked>
            <button [matTooltip]="displayName+' als Lesezeichen hinzufügen'" mat-icon-button (click)="addBookmark(tab);">
                <mat-icon>bookmark_border</mat-icon>
            </button>
        </ng-template>

        <button matTooltip="Link zu dieser Seite in die Zwischenablage kopieren" mat-icon-button [emccCopyToClipboard]="url" (copied)="openSnackBar('URL in die Zwischenablage kopiert!')">
            <mat-icon>link</mat-icon>
        </button>
    </div>

</div>
