<div class="page-center">
  <div style="width: 300px;">
    <div style="display: flex; justify-content: center; margin-bottom: 1rem;">
      <img src="assets/images/logo-emc.png" />
    </div>
    <form #form="ngForm">
      <div style="display: flex; flex-wrap: wrap;">
        <div style="flex-grow: 1; margin: 0 0.5rem;">
          <!-- INPUT BENUTZERNAME -->
          <lui-input
            placeholder="Benutzername"
            [(ngModel)]="username"
            name="usernameInput"
            required
            hideValidation
            #usernameInput="ngModel"
          >
          </lui-input>
          <lui-input
            type="password"
            [(ngModel)]="password"
            name="passwordInput"
            required
            #passwordInput="ngModel"
            placeholder="Passwort"
            hideValidation
            class="mb-3"
          ></lui-input>
          <lui-alert
            *ngIf="loginMsg"
            [type]="loginMsg.success ? 'success' : 'danger'"
            [message]="loginMsg.message"
          ></lui-alert>
          <lui-button
            type="submit"
            [disabled]="!form.valid"
            label="Anmelden"
            block
            class="d-block w-100"
            (asyncAction)="login($event)"
          >
          </lui-button>
        </div>
      </div>
    </form>

    <!-- <p *ngIf="loginMsg" [ngClass]="loginMsg.success ? 'color-success' : 'color-warn'" style="width: 100%; text-align: center;">{{loginMsg.message}}</p> -->
    <div style="margin: 0 0.5rem;">
      <hr style="margin-top: 2rem;" />
      <div class="space-between-container">
        <a
          href="https://www.emc-kassel.de/impressum.html"
          class="small color-link"
          >Datenschutz
        </a>
        <a
          href="https://www.emc-kassel.de/datenschutz.html"
          class="small color-link"
          >Impressum
        </a>
      </div>
    </div>
  </div>
</div>
