import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterObjectValuesByString'
})
export class FilterObjectValuesByStringPipe implements PipeTransform {

  transform(obj: any[], searchString: string, keyList?:string[], allIfEmpty?: boolean): any {
  // const regExp = new RegExp(searchString, 'i');
  allIfEmpty = allIfEmpty || false;

  if (!obj) {
    return [];
  }
  if (!searchString || searchString === '') {
    if (allIfEmpty) {
      return obj;
    } else {
      return [];
    }
  }


 return this.filter(obj, searchString, keyList)
  }

  filter(obj,searchString,keyList){
    return obj.filter(inArr =>
      Object.keys(inArr).some(key => {
        if(!keyList || keyList.indexOf(key.toString())>= 0) {
          if (typeof(inArr[key]) === 'string') {
            if (inArr[key].toLowerCase().includes(searchString.toLowerCase())) {
              return inArr;
            }
          }else if(typeof(inArr[key] === 'object')){
            if(this.filter([inArr[key]],searchString, keyList).length > 0){
              return inArr;
            }
          }
        }
      })
      );

  }
}
