import { Injectable, OnDestroy } from '@angular/core';
import { Tab } from '@app/interfaces/tab';
import { EMCSService } from './emcs.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService implements OnDestroy {
  public bookmarkDragging = new BehaviorSubject<boolean>(false);
  private _bookmarkList: Tab[] = [];
  public bookmarkList = new BehaviorSubject<Tab[]>([]);
  private subscriptionLoggedIn: Subscription;

  isBookmark(tab:Tab) {
    if(tab){
      return this._bookmarkList.find(bookmark => {
        return (
          bookmark.moduleName === tab.moduleName
          && bookmark.url.main === tab.url.main
          && bookmark.url.deep.join() === tab.url.deep.join())
          && ((bookmarkData,tabData)=>{
            if(bookmarkData && tabData){
              const bookmarkDataKeys = Object.keys(bookmarkData);
              const tabDataKeys = Object.keys(tabData);

              if(bookmarkDataKeys.length !== tabDataKeys.length){
                return false;
              }

              bookmarkDataKeys.forEach(dataKey => {
                if(bookmarkData[dataKey] !== tabData[dataKey]){
                  return false;
                }
              })
            }else{
              if(bookmarkData || tabData){
                return false;
              }else{
                return true;
              }
            }
          })(bookmark.url.data,tab.url.data)
      });
    }else{
      return undefined;
    }
  }

  addBookmark(tab: Tab) {
    if (!this.isBookmark(tab)) {
      this._bookmarkList.push(tab);
      this.bookmarkList.next(this._bookmarkList);
      this.updateUserSetting().subscribe();
    }
  }

  updateUserSetting() {
    console.log(this._bookmarkList);
      const body = {
        values: {
          appkey: 'bookmarks',
          json: JSON.stringify(this._bookmarkList)
        }
      };
      return this.emcsService.emcsGet('glob', 'settingUserUpdate', JSON.stringify(body));
  }

  getBookmarkList() {
    const body = {
      values: {
        appkey: 'bookmarks'
      }
    };

    return this.emcsService.emcsGet('glob', 'settingUserFind', JSON.stringify(body));

  }

  updateBookmarkList(bookmarkList: Tab[]){
    this._bookmarkList = bookmarkList;
    this.updateUserSetting().subscribe();
  }

  removeBookmark(tab: Tab) {
    const bookmark = this.isBookmark(tab);
    const index = this._bookmarkList.indexOf(bookmark)
    if(index >= 0){
      this._bookmarkList.splice(index, 1);
    }
    this.bookmarkList.next(this._bookmarkList);
    this.updateUserSetting().subscribe();
  }
  constructor(private emcsService: EMCSService, private authService: AuthService) {
    this.subscriptionLoggedIn = this.authService.loggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.getBookmarkList().pipe(
          take(1)
        ).subscribe(res => {
          this._bookmarkList = JSON.parse(res[0]?.values.setting_json);
          this.bookmarkList.next(this._bookmarkList);
        });
      } else {
        this._bookmarkList = [];
        this.bookmarkList.next(this._bookmarkList);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionLoggedIn.unsubscribe();
  }
}
