import { Injectable, OnDestroy} from '@angular/core';
import { Tab } from '@app/interfaces/tab';
import { Router} from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { EMCSService } from './emcs.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TabService implements OnDestroy {
  private _activeTab: Tab;
  public activeTab = new Subject<Tab>();

  private _tabs: Tab[] = [];
  public tabs = new Subject<Tab[]>();

  private subscriptionLoggedIn: Subscription;
  private loggedIn: boolean = this.authService.loggedIn.value;

  appList = new BehaviorSubject<Tab[]>([
    // {displayName: 'DataComponentShowcase', moduleName: 'DataComponentShowcase',   url: {main: 'data-component-showcase'} },
    // {displayName: 'Materialverwaltung', moduleName: 'Warehouse',   url: {main: 'warehouse' }},
    // {displayName: 'SQL-Konfigurator', moduleName: 'sql-config',   url: {main: 'sql-config' }},
    // {displayName: 'SQL-Tester', moduleName: 'sql-tester',   url: {main: 'sql-tester' }},
    // {displayName: 'App-Konfigurator', moduleName: 'emcc-app-config',   url: {main: 'emcc-app-config' }},
    // {displayName: 'Test', moduleName: 'Test1',   url: {main: 'test1' }},
    // {displayName: 'Fabix', moduleName: 'Fabix',   url: {main: 'fabix' }},
    {displayName: 'taxi.de', moduleName: 'taxide',   url: {main: 'taxide' }},
  ]);
  constructor(private router: Router, private emcs: EMCSService, private authService: AuthService) {
    this.subscriptionLoggedIn = this.authService.loggedIn.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        this.getAppList();
      }
    });
  }

  addTab(moduleTab: Tab) {
      this._tabs.push(moduleTab);
      this.tabs.next(this._tabs);
      // this.tabs.push(name);
  }

  getAppList() {
    this.emcs.emcsGet('glob', 'appList').subscribe((res: any[]) => {

      const appList: Tab[] = res.map(
          x => {
              return {
                displayName: x.values.app_label,
                moduleName: x.values.app_key,
                url: {
                  main:  x.values.app_key
                }
              };
            }
          );
      // this.appList.next(appList);
    });
  }


  activateTab(tab: Tab) {
    this._activeTab = tab;
    this.activeTab.next(this._activeTab);
  }

  deleteTab(tab: Tab) {
    this._tabs.splice(this._tabs.indexOf(tab), 1);
    this.tabs.next(this._tabs);

    if (this._activeTab === tab && this._tabs.length !== 0) {
      this.activateTab(this._tabs[0]);
    }
  }

  createNavigationParams(tab: Tab) {
    let url: string[] = [tab.url.main];

    if (tab.url.sub) {
      url.push(tab.url.sub);
      if (tab.displayName) {
        url.push(tab.displayName);
      }
    }
    if (tab.url.deep) {
      url = [...url, ...tab.url.deep];
    }

    if (tab.url.data) {
        url.push(tab.url.data);
    }
    return ['/app', { outlets: { [tab.moduleName] : url } }];
  }

  navigateTab(tab: Tab, noOverwrite?: boolean) {
    // wenn der "noOverwrite"-Parameter gesetzt ist und das tab bereits geöffnet ist wird keine Navigation ausgeführt und dieses nur aktiv gesetzt

    const openedTab = this._tabs.find(tabsItem => {
      if (tabsItem.moduleName === tab.moduleName) {

        return true;
      }
    });

    // console.log(!noOverwrite +"||"+ !openedTab +"||" +(this._activeTab == tab))
    if (!noOverwrite || !openedTab || this._activeTab == tab) {

      this.router.navigate(this.createNavigationParams(tab));   // ,{skipLocationChange: true}}
    }

    if(openedTab){
      this.activateTab(openedTab);
    }

  }
  closeTab(tab: Tab) {
    this.router.navigate(['/app', { outlets: { [tab.moduleName] : null } }]);
  }

  destroy() {
    for (const tab of this._tabs) {
      this.closeTab(tab);
    }
    this.activeTab.next(null);
  }

  ngOnDestroy() {
    this.subscriptionLoggedIn.unsubscribe();
  }
}
