<button
  #button
  [ngStyle]="{ width: width }"
  mat-ripple
  [ngClass]="buttonClassList"
  [disabled]="disabled || disabled === '' || loading"
>
  <span *ngIf="!loading">{{ label }}</span>
  <span *ngIf="loading" [ngClass]="loaderClassList"></span>
</button>
