import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[emccAutoSizeInput]'
})
export class AutoSizeInputDirective  {
  _value: number;
  @Input('emccAutoSizeInput')
  set value(value: number) {
    if (value > 0) {
      this.el.nativeElement.size = value;
    } else {
      this.el.nativeElement.size = 1;
    }
    this._value = value;
  }
  get value(): number {
    return this._value;
  }
  constructor(private el: ElementRef) {}
}
