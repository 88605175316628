<div class="alert fade show" [class]="backgroundClass" role="alert">
  <span class="alert__message">
    <strong>{{ typeMessage }}</strong> {{ message }}
  </span>
  <!-- <button *ngIf="dismissible" mat-icon-button> -->
  <mat-icon
    *ngIf="dismissible"
    style="width: 20px; height: 20px;"
    class="cursor-pointer"
    (click)="onClose()"
    >close</mat-icon
  >
  <!-- </button> -->
</div>
