import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { RouterOutlet, ChildrenOutletContexts } from '@angular/router';
import { TabService} from '@services/tab-service.service';
import { Tab } from '@app/interfaces/tab';

@Component({
  selector: 'emcc-tab-router-outlet',
  templateUrl: './tab-router-outlet.component.html',
  styleUrls: ['./tab-router-outlet.component.css']
})


export class TabRouterOutletComponent implements OnInit, OnDestroy {
    public outlet: RouterOutlet;
    @Input() public module: Tab;

    constructor(
        private parentContexts: ChildrenOutletContexts,
        private location: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private changeDetector: ChangeDetectorRef,
        private tabService: TabService
    ) {}
    ngOnInit() {
      this.outlet = new RouterOutlet(this.parentContexts, this.location, this.resolver, this.module.moduleName, this.changeDetector);
      this.outlet.activateEvents.subscribe(closed => {this.tabService.addTab(this.module); this.tabService.activateTab(this.module); });
      this.outlet.deactivateEvents.subscribe(closed => {this.tabService.deleteTab(this.module); });
      this.outlet.ngOnInit();

    }
    ngOnDestroy() {
      if (this.outlet) {
        this.outlet.activateEvents.unsubscribe();
      }
      this.outlet.deactivateEvents.unsubscribe();
      this.outlet.ngOnDestroy();
    }
  }
