import { Injectable } from '@angular/core';
import { ApplicationScaffoldComponent } from './application-scaffold.component';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  test:string;

  constructor() { }
}
