


<div class="lui-input__container">
  <label *ngIf="label" class="lui-input__label color-grey-700" for="inputElement">{{label}}<span *ngIf="!!required || required == ''"> *</span></label>
  <div *ngIf="!label && (placeholder || placeholder =='')" style="overflow: hidden;">
    <label  for="inputElement" class="lui-input__placeholder-label color-grey-600" [ngClass]="(value=='' || !value) && type !='date' ? '':'lui-input__placeholder-label--show'">{{placeholder}}<span *ngIf="!!required || required == ''"> *</span></label>
  </div>
  <div class="border color-grey-700 align-center-container" [ngClass]=" !!disabled || disabled===''? ['lui-input--disabled','cursor-default'] : ['bg-dialog', 'cursor-text']"  style="max-height: 36px; box-sizing: border-box">
    <div *ngIf="icon" style="padding: 0 .7rem; height: 36px" class="align-center-container border-right cursor-default" >
      <mat-icon [ngClass]=" !!disabled || disabled===''? 'color-grey-600' : 'color-grey-700'">{{icon}}</mat-icon>
    </div>

    <div class="lui-input__input-container align-center-container" [ngClass]="(!hideValidation && !(hideValidation =='')) && (showValidation || showValidation =='' || !!required || required === '' || minLength || maxLength || pattern || !!email || email === '') ? inputContainerClasses : ''" (mousedown)="focusInput()" (touchstart)="focusInput()" style="overflow: hidden">
        <!-- <span #textMeasure class="input-font" style="white-space: pre; position: absolute;  z-index: -1">{{value}}</span> -->
        <!-- <span *ngIf="prefix && value != ''" class="suffix no-select input-font" [ngClass]=" !!disabled || disabled===''? 'color-grey-600' : 'color-grey-700'">{{prefix}}</span> -->
        <!-- [ngStyle]="{'width.px': (value != '' || !value) ? width : undefined, 'flex-grow': (value != '' || !value) ? '0' : '1'}" -->

        <input
          style="flex-grow: 1"
          [placeholder]="placeholder ? !!required || required == '' ? placeholder+' *' : placeholder : ''"
          id="inputElement"
          #inputElement="ngModel"
          [type]="type"
          [required]="!!required || required === ''"
          [minlength]="minLength"
          [maxlength]="maxLength"
          [attr.pattern]="pattern"
          [email]="!!email || email === ''"
          [disabled]="!!disabled || disabled===''"
          class="input-element input-font w-100"
          [ngClass]=" !!disabled || disabled===''? ['lui-input--disabled', 'color-grey-600'] : ['bg-dialog', 'color-grey-700']"
          [ngModel]="value"
          (ngModelChange)="writeValue($event)"
        >
        <!-- <span *ngIf="suffix && value != ''" class="suffix no-select input-font" [ngClass]=" !!disabled || disabled===''? 'color-grey-600' : 'color-grey-700'" [class]="suffixClass">{{suffix}}</span> -->
    </div>
    <lui-button [disabled]="inputElement.invalid || !!disabled || disabled===''" noRadiusLeft *ngIf="button" color="grey" (click)="onClick()" [label]="button"></lui-button>

  </div>
  <label *ngIf="!inputElement.errors && (!hint || hint == '')" for="inputElement" class="lui-input__hint color-grey-600">{{hint}}</label>
  <label *ngIf="inputElement.errors?.required && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-input__hint color-danger">{{requiredError}}</label>
  <label *ngIf="inputElement.errors?.minlength && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-input__hint color-danger">{{minLengthError}}</label>
  <label *ngIf="inputElement.errors?.maxlength && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-input__hint color-danger">{{maxLengthError}}</label>
  <label *ngIf="inputElement.errors?.email && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-input__hint color-danger">{{emailError}}</label>
  <label *ngIf="inputElement.errors?.pattern && inputElement.touched && !hideValidation && hideValidation!=''" for="inputElement" class="lui-input__hint color-danger">{{patternError}}</label>
</div>

